<template>
  <div
    componente-name="text-copyright"
    v-html="
      `&#169; Copyright ${year} Mirage — ${$t.labels.vat_number} 00175990365`
    "
  ></div>
</template>

<script setup>
const year = computed(() => {
  return new Date().getFullYear();
});
</script>
