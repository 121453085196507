export const useModalBlocker = () => {
  let observer = null;

  function start(lenis, showPageCurtain) {
    const targetNode = document.querySelector("body");
    const config = { attributes: false, childList: true, subtree: false };
    let hasPortalRoot = false;
    const callback = (mutationList, observer) => {
      for (const mutation of mutationList) {
        if (mutation.type === "childList") {
          // get if id="headlessui-portal-root" is present in the body
          const portalRoot = document.getElementById("headlessui-portal-root");
          if (portalRoot && !hasPortalRoot) {
            lenis.stop();
            hasPortalRoot = true;
          } else if (hasPortalRoot && !portalRoot) {
            if (!showPageCurtain?.value) {
              lenis.start();
            }
            hasPortalRoot = false;
          }
        }
      }
    };

    const observer = new MutationObserver(callback);
    observer.observe(targetNode, config);
  }

  function stop() {
    if (observer) {
      observer.disconnect();
    }
  }

  return {
    start,
    stop,
  };
};
